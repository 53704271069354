<template>
  <v-row v-if="pageRender" no-gutters>
    <v-col lg="5" md="5" sm="12">
      <p class="primary--text headline">INTRODUCTION BEEZCARD</p>
      <intro-card-for-preview
        :userCardData="userCardData"
        :card="card"
        :imageUrl="imageUrl"
        :enableEdit="true"
      />
    </v-col>
    <v-col lg="5" md="5" sm="12">
      <p class="primary--text headline">WELCOME CARD</p>

      <welcome-card-preview :cardData="cardData" :cardLinks="cardLinks" :isUser="true"/>
    </v-col>
  </v-row>
  <div v-else class="d-flex justify-center align-center" style="height: 50vh;">
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import IntroCardForPreview from "../introduction/IntroCardForPreview";
import WelcomeCardPreview from "../../../../../beez-admin/modules/on-boarding/pages/WelcomeCardPreview";

export default {
  name: "Dashboard",
  components: {
    IntroCardForPreview,
    WelcomeCardPreview
  },
  computed: {
    ...mapFields("auth", ["currentUser"])
  },
  methods: {
    ...mapActions("welcome", {
      getWelcomeCard: "getWelcomeCard"
    }),
    ...mapActions("introduction", {
      getIntroductionCard: "getIntroductionCard"
    }),
    ...mapActions("user", {
      getUserCard: "getUserCard",
      finishOnBoardingProcess: "finishOnBoardingProcess"
    }),
    ...mapActions("admin", {
      fetchFilesUploaded: "fetchFilesUploaded"
    }),
    setCard(card) {
      this.card.id = card[0]._id;
      this.card.jobTitle = card[0].jobTitle;
      this.card.aboutTitle = card[0].aboutTitle;
      let sections = [];
      let sectionObj = {};
      for (var index in card[0].sections) {
        sectionObj["id"] = card[0].sections[index]._id;
        sectionObj["title"] = card[0].sections[index].title;
        sections.push(sectionObj);
        sectionObj = {};
      }
      this.card.sections = sections;
      this.userCardData.cardTags[0].cardCustomizationSectionId =
        card[0].cardCustomizationSectionIds[0];
      this.userCardData.cardTags[1].cardCustomizationSectionId =
        card[0].cardCustomizationSectionIds[1];
      this.userCardData.accountModuleId = card[0].accountModuleId;
      this.userCardData.cardCustomizationId = card[0]._id;
      this.userCardData.fullName =
        this.currentUser.firstName + " " + this.currentUser.lastName;
    },
    fillCard(cardData) {
      this.userCardData.position = cardData[cardData.length - 1].position;
      this.userCardData.about = cardData[cardData.length - 1].about;
      let tagsA = [];
      let tagsB = [];
      cardData[cardData.length - 1].cardTags.forEach(function(item, index) {
        if (index < 3) {
          tagsA.push(item.tag);
        } else {
          tagsB.push(item.tag);
        }
      });
      this.userCardData.cardTags[0].tags = tagsA;
      this.userCardData.cardTags[1].tags = tagsB;
      this.userCardData.profilePictureId =
        cardData[cardData.length - 1].profilePictureId;
    }
  },
  data: function() {
    return {
      pageRender: false,
      cardData: {
        title: "",
        description: "",
        media: "",
        mediaType: "",
        mediaUrl: ""
      },
      cardLinks: {
        link1: "",
        link2: "",
        link3: ""
      },
      card: {
        id: null,
        jobTitle: "",
        aboutTitle: "",
        sections: ["", ""]
      },
      userCardData: {
        profilePictureId: "",
        fullName: "",
        about: "",
        position: "",
        cardCustomizationId: "",
        cardTags: [
          {
            cardCustomizationSectionId: "",
            tags: ["", "", ""]
          },
          {
            cardCustomizationSectionId: "",
            tags: ["", "", ""]
          }
        ],
        accountModuleId: ""
      },
      imageUrl: null,
      accountId: null
    };
  },
  async created() {
    this.accountId = this.currentUser.roles[0].accountId;

    const card = await this.getIntroductionCard({ accountId: this.accountId });
    const cardData = await this.getUserCard();
    if (card && card.length > 0) {
      this.setCard(card);
    }
    if (cardData && cardData.length > 0) {
      this.finishOnBoardingProcess();
      this.fillCard(cardData);
    } else {
      this.$router.replace({ name: "onboarding-landing-page" });
    }
    // console.log(this.userCardData.profilePictureId);
    if (this.userCardData.profilePictureId) {
      try {
        const userImage = await this.fetchFilesUploaded({
          logoId: this.userCardData.profilePictureId
        });
        if (userImage) {
          this.image = userImage;
          this.imageUrl = userImage.url;
        }
        // console.log(userImage, this.imageUrl);
      } catch (error) {}
    }
    let [{ title, description, file, links }] = await this.getWelcomeCard({
      accountId: this.accountId
    });
    // let { data } = await this.getIntroductionCard({
    //   accountId: accountId
    // });

    this.cardData.title = title;
    this.cardData.description = description;
    if (file) {
      this.cardData.media = {
        id: file._id,
        type: file.contentType,
        url: file.url
      };
      this.cardData.mediaType = file.contentType;
      this.cardData.mediaUrl = file.url
    }

    this.cardLinks.link1 = links[0];
    this.cardLinks.link2 = links[1];
    this.cardLinks.link3 = links[2];
    this.pageRender = true;
  }
};
</script>

<style scoped>
.introduction-card-container {
  height: 40%;
  border: 2px solid red;
}
.intro {
  height: 100%;
}
</style>
