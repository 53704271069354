<template>
  <div class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="pa-0 mt-2 ma-0" cols="11" md="12" lg="12">
        <v-card
          flat
          class="card-shadow ma-0 pa-0 d-flex flex-column"
          max-width="320"
          height="450"
          align="left"
        >
          <v-row
            cols="12"
            lg="12"
            sm="12"
            class="textDark ma-0 pa-4 d-flex flex-row bg-image-container"
          >
            <v-col
              class="ma-0 pa-2 pt-5 d-flex flex-column align-self-start text-light"
              lg="5"
              md="5"
              cols="12"
            >
              <v-img max-width="115" max-height="115" class="pa-8" :src="imageUrl ? imageUrl : ''"></v-img>
            </v-col>

            <v-col lg="7" cols="12" class="d-flex align-center justify-center">
              <v-row class="ma-0 pa-0 textLight--text">
                <v-col
                  lg="12"
                  cols="12"
                  class="textLight--text font-weight-regular text-uppercase ma-0 pa-0 ml-2 text-container"
                >{{ currentUser.firstName }} {{ currentUser.lastName }}</v-col>
                <v-col
                  lg="12"
                  cols="12"
                  class="primaryLight--text font-weight-light text-capitalize ma-0 pa-0 ml-2"
                >
                  {{
                  userCardData.position != ""
                  ? userCardData.position
                  : card.jobTitle
                  }}
                </v-col>
              </v-row>
            </v-col>
            <div class="buttonDivStyle ma-3" v-if="enableEdit">
              <v-btn
                small
                color="red"
                class="textLight--text"
                :to="{name: 'introduction-build',  query: { edit: true }}"
              >EDIT</v-btn>
            </div>
          </v-row>
          <div class="mx-5 mt-4 mb-0 pa-0">
            <p class="mx-0 mt-0 mb-1 pa-0 font-weight-bold">{{card.aboutTitle}}</p>
            <p class="ma-0 pa-0 caption">{{ userCardData.about }}</p>
          </div>
          <v-row class="pa-0 d-flex flex-row ma-4">
            <v-col
              class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
              lg="6"
              md="6"
              sm="6"
            >
              <div class="d-flex align-self-start pa-0 ma-0">
                {{
                card.sections[0].title == ""
                ? "Expertise"
                : card.sections[0].title
                }}
              </div>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="ma-0 pa-0 d-flex align-self-start justify-start"
              >
                <bz-tags
                  class="ma-0 pa-0"
                  darkColor="primaryLight"
                  lightColor="lightBlue"
                  textColor="primaryLight--text"
                  :listOfTags="userCardData.cardTags[0].tags"
                  :small="true"
                />
              </v-col>
            </v-col>

            <v-col
              class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
              lg="6"
              md="6"
              sm="6"
            >
              <div class="d-flex align-self-start pa-0 ma-0">
                {{
                card.sections[1].title == ""
                ? "Projects"
                : card.sections[1].title
                }}
              </div>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="ma-0 pa-0 d-flex align-self-start justify-start"
              >
                <bz-tags
                  class="ma-0 pa-0"
                  darkColor="textOrange"
                  lightColor="lightOrange"
                  textColor="textOrange--text"
                  :listOfTags="userCardData.cardTags[1].tags"
                  :small="true"
                />
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import BzTags from "@shared/components/BzTags";
export default {
  name: "IntroCardForPreview",
  components: {
    BzTags
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    userCardData: {
      type: Object,
      required: true
    },
    imageUrl: {
      type: String,
      required: false
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapFields("auth", ["currentUser"])
  }
  // watch: {
  //   imageUrl(a, b){
  //     this.imageUrl = a;
  //     console.log("Changed", a, b);
  //   }
  // }
};
</script>

<style scoped>
.bg-image-container {
  background: #4e4e4e url("../../../../../assets/dark_backdrop_user.svg") 0% 0%
    padding-box;
  border-radius: 3px;
  opacity: 1;
}
.card-shadow {
  overflow: hidden;
  box-shadow: 0px 0px 75px #00000024;
}
.text-container {
  word-break: break-all;
  /* display: block;
  /* overflow: auto; */
}
.about-container {
  height: 65px;
}
.job-title-container {
  width: 150px;
  height: 50px;
}
.buttonDivStyle {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
