<template>
  <div>
    <v-card class="links-card mt-6" align="left" max-width="350" height="450">
      <v-row no-gutters class="d-flex flex-column fill-height" color="grey">
        <div class="image-container ma-0 pa-0 d-flex justify-start align-start" v-if="cardData.mediaType">
          <div class="video-container" v-if="cardData.mediaType.includes('video')">
            <div class="video-button-container d-flex align-items-center justify-center">
              <v-icon class="display-4 video-button" @click="videoPlayer = true">mdi-play</v-icon>
            </div>
            <video class="video" :src="cardData.media.url" loop playsinline preload="auto"></video>
          </div>

          <v-img
            v-else-if="cardData.mediaType.includes('image')"
            class="pa-0 ma-0"
            :src="cardData.mediaUrl"
            width="350"
            height="172"
          ></v-img>
          <div class="welcomeFooter d-flex align-self-end"></div>
          <div class="absoluteTitle d-flex align-self-end ma-0 pt-1 pl-3">
            <p
              class="ma-0 pa-0 textLight--text darken-3 title text-uppercase d-flex align-self-start justify-left"
            >{{cardData.title}}</p>
          </div>
        </div>
        <div v-else class="image-container ma-0 pa-0 d-flex justify-start align-start">
          <v-img width="350" class="pa-0 ma-0" src="../../../../assets/Welcome_Home.png"></v-img>
          <div class="welcomeFooter d-flex align-self-end"></div>
          <div class="absoluteTitle d-flex align-self-end ma-0 pt-1 pl-3">
            <p
              v-if="cardData.title"
              class="ma-0 pa-0 textLight--text darken-3 title text-uppercase d-flex align-self-start justify-left"
            >{{cardData.title}}</p>
            <p
              v-else
              class="ma-0 pa-0 textLight--text darken-3 title text-uppercase d-flex align-self-start justify-left"
            >Welcome to Africa PLC</p>
          </div>
          <!-- <div class="textDark title-container white--text d-flex justify-start pl-3 title">
            <p style="opacity: 0.8" class="ma-0"></p>
          </div>-->
        </div>
        <v-col class="description-container">
          <div>
            <div class="text-container ml-3 mt-1">
              <p
                class="subtitle-2 textDark--text font-weight-light"
                v-if="cardData.description"
              >{{ cardData.description }}</p>
              <p
                class="subtitle-2 textDark--text font-weight-light"
                v-else
              >We would like to Congratulate you for Joining Our Family</p>
            </div>
          </div>
          <div class="ml-0 ml-2 mr-2">
            <div v-if="cardLinks">
              <div class="ml-2 mt-4 subtitle-1">Links</div>
              <div>
                <bz-tags
                  class="mb-0"
                  cardWidth="200"
                  darkColor="primary"
                  lightColor="lightBlue"
                  textColor="primaryLight--text"
                  :isLink="true"
                  :listOfTags="
                  !isUser ? 
                  [
                  cardLinks.link1,
                  cardLinks.link2,
                  cardLinks.link3
                  ] : filledLinks"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="videoPlayer" v-if="videoPlayer" max-width="800">
      <video
        class="video-player-landscape"
        controls
        :src="cardData.media.url"
        autoplay
        loop
        playsinline
        preload="auto"
        __idm_id__="657681409"
      ></video>
    </v-dialog>
  </div>
</template>

<script>
import BzTags from "@shared/components/BzTags";
export default {
  name: "WelcomeCardPreview",
  components: {
    BzTags
  },
  props: {
    cardData: {
      type: Object
    },
    cardLinks: {
      type: Object,
      default() {
        return {
          link1: ""
        };
      }
    },
    isUser: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      tags: [],
      videoPlayer: false,
      defaultLinks: ["www.africa.com", "www.africa.com", "www.africa.com"]
    };
  },
  computed: {
    filledLinks() {
      let filledLinksArray = [];
      if (this.cardLinks.link1 !== "") {
        filledLinksArray.push(this.cardLinks.link1);
      }
      if (this.cardLinks.link2 !== "") {
        filledLinksArray.push(this.cardLinks.link2);
      }
      if (this.cardLinks.link3 !== "") {
        filledLinksArray.push(this.cardLinks.link3);
      }
      return filledLinksArray;
    }
  },
  // watch: {
  //   tags: function() {
  //     this.tags.push(this.cardLinks.link1);
  //     this.tags.push(this.cardLinks.link2);
  //     this.tags.push(this.cardLinks.link3);
  //   }
  // }
};
</script>

<style scoped lang="scss">
.header-container {
  width: 100%;
  display: flex;
  border-radius: 6px;
  height: 158px;
  justify-content: center;
}
.header-container img {
  border-radius: 3px;
  border-radius: 6px;
}
.text-container {
  width: 90%;
  height: 40px;
}
.title-container {
  height: 40px;
}
.links-card {
  margin-top: 10px;
  box-shadow: 0px 0px 99px #00000029;

  border-radius: 6px;
}
.welcome-image {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.tag-container {
  margin-top: -12px;
  width: 95%;
  margin-left: 8px;
}
.tag-content {
  opacity: 0.5;
}
.video-container {
  position: relative;
}
.video-button-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.video-button:hover {
  cursor: pointer;
}
.video {
  width: 100%;
  max-width: 600px;
  height: auto;
}
.welcomeFooter {
  position: absolute;
  background: black;
  opacity: 0.5;
  width: 100%;
  height: 40px;
}
.image-container {
  height: 172px;
}
.absoluteTitle {
  position: absolute;
}
</style>
